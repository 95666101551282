import Fetcher, { OAuthRequest } from './../../core/fetch';

export function GetUser() {
    return OAuthRequest('/info', `GET`);
}

export function getUserTokens(key = '') {
    return Fetcher('/users/auth/tokens', 'POST', { key });
}

export function validateToken() {
    return Fetcher(`/users/auth/verify`, `GET`);
}

export function refreshToken() {
    return Fetcher(`/users/auth/refresh`, `PUT`, null, {}, true);
}

export function loadUsers(page = 1, filter = {}) {
    let params = [];
    Object.keys(filter).map(i => {
        if (filter[i] !== "") {
            params.push(`${i}=${encodeURIComponent(filter[i])}`);
        }
        return i;
    });
    return Fetcher(`/users?page=${page}&${params.join('&')}`, `GET`);
}

export function updateUsers(user_id = '', data = {}) {
    return Fetcher(`/users/${user_id}`, `PUT`, data);
}

export function loadUserInfo(id = '') {
    return Fetcher(`/users/${id}`, `GET`);
}

