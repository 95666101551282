export const NOTIFICATIONS_LOAD_START = 'NOTIFICATIONS_LOAD_START';
export const NOTIFICATIONS_LOAD_FINISH = 'NOTIFICATIONS_LOAD_FINISH';
export const NOTIFICATIONS_LOAD_ERROR = 'NOTIFICATIONS_LOAD_ERROR';

export const NOTIFICATION_CREATE_START = 'NOTIFICATION_CREATE_START';
export const NOTIFICATION_CREATE_FINISH = 'NOTIFICATION_CREATE_FINISH';
export const NOTIFICATION_CREATE_ERROR = 'NOTIFICATION_CREATE_ERROR';

export const NOTIFICATION_DELETE_START = 'NOTIFICATION_DELETE_START';
export const NOTIFICATION_DELETE_ERROR = 'NOTIFICATION_DELETE_ERROR';
export const NOTIFICATION_DELETE_FINISH = 'NOTIFICATION_DELETE_FINISH';

export const NOTIFICATION_UPDATE_START = 'NOTIFICATION_UPDATE_START';
export const NOTIFICATION_UPDATE_FINISH = 'NOTIFICATION_UPDATE_FINISH';
export const NOTIFICATION_UPDATE_ERROR = 'NOTIFICATION_UPDATE_ERROR';