import Config from './../../config';
import * as Translates from './../../config/translates';

const Locale = Translates[Config.LOCALE] || {};

export const t = (text) => {
    if (text === undefined) return "";
    return Locale[text.toUpperCase()] || text.toUpperCase();
};

export const tnum = (text, num) => {
    let val = num % 100;
    let l = Locale[text] || [];
    l = Array.isArray(l) ? l : [];

    if (val > 10 && val < 20) {
        return l[2];
    } else {
        val = num % 10;
        if (val === 1) {
            return l[0];
        } else {
            if (val > 1 && val < 5) {
                return l[1];
            } else {
                return l[2];
            }
        }
    }
};
