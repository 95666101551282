export const HOMEWORKS_LOAD_START = 'HOMEWORKS_LOAD_START';
export const HOMEWORKS_LOAD_FINISH = 'HOMEWORKS_LOAD_FINISH';
export const HOMEWORKS_LOAD_ERROR = 'HOMEWORKS_LOAD_ERROR';

export const HOMEWORK_RATE_START = 'HOMEWORK_RATE_START';
export const HOMEWORK_RATE_FINISH = 'HOMEWORK_RATE_FINISH';
export const HOMEWORK_RATE_ERROR = 'HOMEWORK_RATE_ERROR';

export const HOMEWORK_LOAD_START = 'HOMEWORK_LOAD_START';
export const HOMEWORK_LOAD_FINISH = 'HOMEWORK_LOAD_FINISH';
export const HOMEWORK_LOAD_ERROR = 'HOMEWORK_LOAD_ERROR';

export const HOMEWORK_DELETE_START = 'HOMEWORK_DELETE_START';
export const HOMEWORK_DELETE_ERROR = 'HOMEWORK_DELETE_ERROR';
export const HOMEWORK_DELETE_FINISH = 'HOMEWORK_DELETE_FINISH';
