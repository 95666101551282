// import MediaSources from "../../media_sources";
// import {parseUrlParams} from "../../../core/library/url";

// const PROJECT = process.env.PROJECT || "";

export const dev = {
    SHOW_LANGS: true,
    APP_CLASS: 'dev',
    PROJECT_NAME: 'RT-School',
    PROJECT_PREFIX: 'rts',
    LANGS: [
        {title: "Russian", domain: "http://ru.localhost:3000"}
    ],

    VIEWS: {},
    MENU: [
        {
            'title': 'DASHBOARD',
            'path': '/',
            'component': 'dashboard',
            'icon': 'dashboard',
        },
        {
            'title': 'Profiles',
            'path':'/profiles',
            'icon': 'user',
            'submenu': [
                {
                    'title': 'Students',
                    'path': '/profiles',
                    'component': 'profiles_controller',
                    'icon': 'user',
                },
                {
                    'title': 'TEACHERS_LIST',
                    'path': "/teachers",
                    'component': 'teachers_controller',
                    'icon': 'user',
                },
            ],
            'subroutes': [
                {
                    'path': '/profiles/:id',
                    'component': 'profile_edit_controller',
                },
                {
                    'path': '/teachers/add',
                    'component': 'teacher_add',
                },
                {
                    'path': '/teachers/:id',
                    'component': 'teacher_add'
                }
            ]
        },
        {
            'title': 'SUBMITS_LIST',
            'path': '/submits',
            'component': 'submits_controller',
            'icon': 'mail',
            'subroutes': [
                {
                    'path': '/submits/:id',
                    'component': 'submits_single_controller',
                }
            ]
        },
        {
            'title': 'COURSES_LIST',
            'path': '/courses',
            'component': 'courses_controller',
            'icon': 'number',
            'subroutes': [
                {
                    'path': '/courses/add',
                    'component': 'courses_add',
                },
                {
                    'path': '/courses/:id',
                    'component': 'courses_add',
                },
                {
                    'path': '/courses/:id/preview',
                    'component': 'course_preview'
                }
            ]
        },
        {
            'title': 'STAGES_LIST',
            'path': '/stages',
            'component': 'stages_controller',
            'icon': 'flag',
            'subroutes': [
                {
                    'path': '/stages/add',
                    'component': 'stage_add',
                },
                {
                    'path': '/stages/:id',
                    'component': 'stage_add',
                },
                {
                    'path': '/stages/:id/preview',
                    'component': 'stage_preview'
                }
            ]
        },
        {
            'title': 'LESSONS_LIST',
            'path': '/lessons',
            'component': 'lessons_controller',
            'icon': 'experiment',
            'subroutes': [
                {
                    'path': '/lessons/add',
                    'component': 'lesson_add',
                },
                {
                    'path': '/lessons/:id',
                    'component': 'lesson_add',
                },
                {
                    'path': '/lessons/:id/preview',
                    'component': 'lesson_preview'
                }
            ]
        },
        {
            'title': 'TEACHERS_LIST',
            'path': "/teachers",
            'component': 'teachers_controller',
            'icon': 'crown',
            'subroutes': [
                {
                    'path': '/teachers/add',
                    'component': 'teacher_add',
                },
                {
                    'path': '/teachers/:id',
                    'component': 'teacher_add'
                },
            ]
        },
        {
            'title': 'HOMEWORKS_LIST',
            'path': '/homeworks',
            'component': 'homeworks_controller',
            'icon': 'file-search',
            'subroutes': [
                {
                    'path': `/homeworks/:id`,
                    'component': 'homework_controller',
                }
            ]
        },
        {
            'title': "MEDIA",
            'path': '/media',
            'component': 'media_controller',
            'icon': 'file-image'
        },
        {
            'title': 'NOTIFICATION_LIST',
            'path': '/notifications',
            'component': 'notification',
            'icon': 'notification',
        },
        {
            'title': "WEBINARS_LIST",
            'path': "/webinars",
            'component': 'webinars_controller',
            'icon': "youtube",
            'subroutes': [
                {
                    'path': '/webinars/add',
                    'component': 'webinar_add'
                },
                {
                    'path': '/webinars/:id',
                    'component': 'webinar_add'
                }
            ]
        },
        {
            'title': 'MAILING',
            'icon': 'bell',
            'path': '/mailing',
            'submenu' : [
                {
                    'title': 'PREREQUESTS_LIST',
                    'path': '/mailing/prerequests',
                    'component': 'preRequests_controller',
                }, 
                {
                    'title': 'STAGES_MAILING',
                    'path': '/mailing/stages',
                    'component': 'mailing_controller'
                }
            ]
        }
    ],
    UPLOAD: {
        "path": "http://localhost:9000/api/v1/media"
    },
};
