export const TEACHER_START_LOAD = 'TEACHER_START_LOAD';
export const TEACHER_FINISH_LOAD = 'TEACHER_FINISH_LOAD';
export const TEACHER_ERROR_LOAD = 'TEACHER_ERROR_LOAD';

export const TEACHERS_START_LOAD = 'TEACHERS_START_LOAD';
export const TEACHERS_FINISH_LOAD = 'TEACHERS_FINISH_LOAD';
export const TEACHERS_ERROR_LOAD = 'TEACHERS_ERROR_LOAD';

export const TEACHER_START_UPDATE = 'TEACHER_START_UPDATE';
export const TEACHER_FINISH_UPDATE = 'TEACHER_FINISH_UPDATE';
export const TEACHER_ERROR_UPDATE = 'TEACHER_ERROR_UPDATE';

export const TEACHER_START_DELETE = 'TEACHER_START_DELETE';
export const TEACHER_FINISH_DELETE = 'TEACHER_FINISH_DELETE';
export const TEACHER_ERROR_DELETE = 'TEACHER_ERROR_DELETE';

export const TEACHER_START_CREATE = 'TEACHER_START_CREATE';
export const TEACHER_FINISH_CREATE = 'TEACHER_FINISH_CREATE';
export const TEACHER_ERROR_CREATE = 'TEACHER_ERROR_CREATE';
