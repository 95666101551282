export const RU = {
    LOADING: 'Идет загрузка',
    DASHBOARD: 'Главная',
    CATEGORIES: 'Рубрики',
    TAGS: 'Теги',
    TRENDS: 'Темы',
    BANNERS: 'Баннеры',
    WIDGETS: 'Виджеты',
    PAGES: 'Страницы',
    USERS: 'Пользователи',
    DOCUMENTS: 'Документы',
    ARTICLE: 'Статья',
    EPISODE: 'Вечерка ТВ',
    GALLERY: 'Галереи',
    INFOGRAPHIC: 'Инфографика',
    AUTHORS: 'Авторы',
    GROUPS: 'Группы',
    PERMISSIONS: 'Права',
    PROFILE: 'Профиль',
    LOGOUT: 'Выйти',
    NO_EVENTS: 'нет событий для вас',
    WELCOME_MESSAGE: 'Добро пожаловать в админику VM core beta 1.2',
    DASHBOARD_WELCOME: 'Добро пожаловать',
    ARTICLE_LIST: 'Список статей',
    EPISODE_LIST: 'Список вечерки тв',
    GALLERY_LIST: 'Список галерей',
    INFOGRAPHIC_LIST: 'Список инфографики',
    LOADING_DOCUMENTS: 'Подождите, идет загрузка',
    EDIT: 'редактировать документ',
    VIEW: 'предпросмотр',
    DELETE: 'удалить документ',
    CROSSLINKS: 'материалы по теме',
    SHOW_ALL_TAGS: 'Показать все теги',
    HIDE_ALL_TAGS: 'Скрыть все теги',
    IMAGE: 'Изображение',
    COVER: 'Обложка',
    VIDEO: 'Видео',
    EXTERNALVIDEO: 'Внешнее видео',
    CODE: 'Код',
    CATEGORY: "Рубрика",
    AUTHOR: 'Автор',
    PUBLISHED: 'опубликован',
    UNPUBLISHED: 'не опубликован',
    PREPUBLISHED: 'готов к публикации',
    CREATE: 'Создать',
    TITLE_OR_URL: 'заголовок или ссылка',
    SELECT_STATUS: 'выберите статус',
    SELECT_TAGS: 'выберите теги',
    SELECT_CATEGORY: 'выберите рубрику',
    DATE_FROM: 'дата начала',
    DATE_TO: 'дата конца',
    ALL: 'все',
    EMPTY_DOCUMENTS: 'нет документов',
    CLEAR_FILTER: 'Очистить фильтр',
    CREATED: 'Создан',
    UPDATED: 'Обновлен',
    AT: 'в',
    SURE_DELETE: 'Вы уверены, что хотите удалить документ?',
    CLOSE: 'Закрыть',
    NOT_SET: '[не заполнено]',
    ARTICLE_ADD: 'Добавить статью',
    UPLOAD_TEXT: 'Кликните по области или перетащите файл',
    COVER_LABEL: 'Обложка',
    UPLOAD_NEW: 'Новое изображение',
    UPLOAD_SEL: 'Архив',

    NEWS: 'Новости',
    ARTICLES: 'Статьи',
    TITLE: 'Заголовок',
    UPDATE_DOCUMENT: 'Сохранить',
    DOC_STATUS: 'Статус',
    CREATE_BY: 'Создан',
    UPDATED_BY: 'Обновлен',
    HISTORY: 'История',
    SUMMARY: 'Лид',
    DELETE_MEDIA: 'Удалить обложку',
    TEXT: 'Текст документа',
    SLUG: 'Слаг (в ссылке)',
    PUBLISHED_AT: 'Опубликовать',
    COORDS: 'Координаты',
    COORDS_HELP: 'Введите радианы места',
    HIDE_MAP: 'Скрыть карту',
    SHOW_MAP: 'Показать карту',
    GENERATE: 'Сгенерировать',
    PUBLISH_IN: 'Опубликовать в',
    SUB_CATEGORY: 'Включен в рубрики',
    VIEW_FIELD: 'Представление',
    SEO_TITLE: 'Seo title',
    SEO_DESCRIPTION: 'Seo description',
    REDIRECT_TO: 'Редирект',
    SEO_AND_VIEW: 'Настройки отображения',
    published: 'опубликован',
    DOCUMENT_HISTORY_EVENT: 'История редактирования документа',
    RECOVER: 'Восстановить',
    TITLE_ERROR_FILL: 'Заполните заголовок документа',
    SUMMARY_ERROR_FILL: 'Заполните лид документа',
    SLUG_PLACEHOLDER: 'Заполните слаг документа',
    SLUG_ERROR: 'Заполните слаг документа',
    SELECT_CATEGORIES: 'Выберите рубрику документа',
    SELECT_AUTHORS_ERROR: 'Выберите авторов документа',
    AUTHORS_PLACEHOLDER: 'Выберите авторов документа',
    TITLE_PLACEHOLDER: 'Введите заголовок',
    SUMMARY_PLACEHOLDER: 'Введите лид',
    SELECT_SUB_CATEGORIES: 'Выберите подрубрику',
    SELECT_TRENDS: 'Выберите темы',
    SELECT_CROSSLINKS: 'Выберите материалы по теме',
    NO_HISTORY: 'Нет истории',
    ARTICLE_EDIT: 'Редактирование статьи',
    EPISODE_ADD: 'Добавление эпизода',
    EPISODE_EDIT: 'Редактирование эпизода',
    COORDS_MAP_HELP: 'Кликнете по карте для отметки места',
    GALLERY_EDIT: 'Редактирование галереии',
    CREATE_BTN: 'Создать',
    PUBLIC_ID: 'ID',
    CREATED_AT: 'Создан',
    UPDATED_AT: 'Обновлен',
    ACTIONS: 'Действия',
    CREATE_DOCUMENT: 'Создать',
    DOCUMENT_UPDATE_ERROR: 'Ошибка обновления документа',
    DOCUMENT_UPDATE_SUCCESS: 'Документ успешно обновлен',
    DOCUMENT_DELETED: 'Документ успешно удален',
    DOCUMENT_DELETE_ERROR: 'Ошибка удаления документа',
    DOCUMENTS_LOAD_ERROR: 'Ошибка загрузки документа',
    REPEAT_AGAIN_LATER: 'Повторите пожалуйста позднее',
    DOCUMENT_UPDATED: 'Документ успешно обновлен',
    LOGIN: 'Войти',
    AUTHORIZATION_WAIT: 'Подождите, идет авторизация',
    AUTHORIZATION_ERROR: 'Ошибка авторизации',
    CLEAR: 'Очистить',
    APPLY: 'Применить',
    SEARCH_MEDIA_PLACEHOLDER: 'Заголовок или копирайт',
    REMOVE: 'Удалить',
    ADD: 'Добавить',
    SOURCE: 'Источник',

    REMOVE_ALL: 'Удалить все',
    SAVE_ALL: 'Сохранить все',
    UPLOAD_FIRST_ROW: 'Кликните или перетащите файл для загрузки',
    UPLOAD_NEXT_ROW: ' ',
    MEDIA: 'Медиа',
    UPLOAD: 'Загрузить',
    SEARCH: 'Поиск',
    MEDIA_LIST: 'Список медиа',
    WIDGET_ADD_TITLE: 'Добавление виджета',
    EDIT_WIDGET: 'Редактирование виджета',
    VIEW_WIDGET: 'Просмотр виджета',
    WIDGET_UPDATED: 'Виджет обновлен',
    WIDGET_CREATED: 'Виджет создан',
    MAX_SIZE: 'Количество документов',
    DOCS_SIZE: 'Количество документов',
    DOC_TYPES: 'Типы документов',
    ADMIN_TITLE: 'Заголовок (админка)',
    WIDGETS_LIST: 'Список виджетов',
    SAVE: 'Сохранить',
    WIDGET_NOT_FOUND: 'Виджет не найден',
    MAXIMUM_ELEMENT_IN_WIDGET: 'Количество элементов в виджете',
    WIDGETS_EDIT: 'Редактирование виджета',
    TAG_LIST: 'Список тегов',
    CATEGORY_LIST: 'Список рубрик',
    AUTHORS_LIST: 'Список авторов',
    PAGES_LIST: 'Список страниц',
    HREF: 'Ссылка',
    TEXT_PLACEHOLDER: 'Текст документа',
    REDIRECT_TO_ERROR: 'Ошибка заполнения редиректа',
    SLUG_FILL_ERROR: 'Ошибка заполнения документа',
    PAGE_LIST: 'Список страниц',
    BANNER_LIST: 'Список баннеров',
    TREND_LIST: 'Список тем',
    TREND_ADD: 'Новая тема',
    TREND_EDIT: 'Редактирование темы',
    BANNER_ADD: 'Добавление баннера',
    BANNER_EDIT: 'Редактирование баннера',
    OTHER: 'Прочее',
    PREVIEW: 'Посмотреть',
    WIDGET_TITLE: 'Заголовок виджета',
    ADMIN_WIDGET_TITLE: 'Заголовок виджета (админка)',
    AUTHOR_LIST: 'Список авторов',
    DOCUMENT_CREATE_FINISH: 'Документ успешно создан',
    AUTHOR_EDIT: 'Редактирование автора',
    AUTHOR_ADD: 'Создание автора',
    LOADING_DOCUMENT: 'Загрузка документа',
    SELECT: 'выбрать',
    VISIBLE: 'Виден на сайте',
    SHOW: 'Рубрики ТВ',
    SHOW_LIST: 'Список рубрик вечерки',
    SHOW_ADD: 'Создать рубрику',
    SHOW_EDIT: 'Редактирование рубрики',
    MEANING: 'Колумнисты',
    MEANING_LIST: 'Колонки',
    MEANING_ADD: 'Добавление колонки',
    MEANING_EDIT: 'Редактирование колонки',
    SUBTITLE: 'Подзаголовок',
    NEWS_LIST: 'Новости',
    NEWS_ADD: 'Создание новости',
    NEWS_EDIT: 'Редактирование новости',
    DRAFT: 'Черновик',
    CHECK_CORRECTOR: 'Проверка корректором',
    CHECK_PLAGIARISM: 'Антиплагиат',
    NEED_PHOTO: 'Установлено фото',
    NEED_VIDEO: 'Установлено видео',
    N_NEED_PHOTO: 'Фото',
    N_NEED_VIDEO: 'Видео',
    WORKFLOW: 'Рабочий процесс',
    NEED_RAYTER: 'Сдано журналистом',
    NEED_EDITOR: 'Проверено редактором',
    NEED_CORRECTOR: 'Проверено корректором',
    ERROR_SET_LOCK: 'Ошибка установки блокировки',
    SUCCESS_SET_LOCK: 'Блокировка успешно установлена',
    IN_MATERIAL: 'В материале',
    DOCUMENT_LOCK: 'Документ заблокирован',
    REMOVE_LOCK: 'Удалить блокировку',
    LOCK_REMOVE: 'Блокировка удалена',
    LOCK_REMOVE_ERROR: 'Ошибка удаления блокировки',
    ERROR_CREATE_DOCUMENT: 'Ошибка создания документа',
    DOCUMENT_CREATED: 'Документ успешно создан',
    DOCUMENT_NOT_FOUND: 'Документ не найден',
    STASH_CHANGES: 'У вас есть не сохраненные изменения',
    DOCUMENT_LAST_EDIT_USER: 'Последнее обновление документа',
    DOCUMENT_LAST_EDIT_STASH: 'Последнее автосохранение',
    DELETE_STASH: 'Удалить автосохранение',
    USER_BY: 'пользователем',
    STATUS: 'Статус',
    PUBLISH_CHECK: "Опубликован, проверить",
    PUBLISH: "Опубликован",
    ERROR_LOAD_WORKFLOW: 'Ошибка загрузки документов',
    STATUS_NOT_SET: 'не установлен',
    IMAGE_FIELD: 'Картинка',
    VIDEO_FIELD: 'Видео',
    RAITER_FIELD: 'Корреспондент',
    EDITOR_FIELD: 'Редактор',
    CHECK_FIELD: 'Корректор',
    VIEWS_AND_COMMENTS: 'Кол-во просмотров/кол-во комментов',
    LAST_UPDATED_WORKFLOW: 'Последнее изменение',
    DATE_PUBLISH: 'Дата публикации',
    WORKFLOW_STATS: 'Статус',
    COVER_WORKFLOW: 'Фото',
    VIEWS_COUNT: 'Количество просмотров',
    COMMENTS_COUNT: 'Количество комментариев',
    LOCK_BY: 'Заблокирован',
    READY: 'Готов',
    ARCHIVE: 'Запас',
    PUBLISH_AT_MAIN: 'Опубликовать на главной',
    PUBLISH_AT_SMI2: 'Опубликовать в smi2',
    PUBLISH_AT_DZEN: 'Опубликовать в dzen',
    PUBLISH_AT_NEWSPAPER: 'Опубликован в газете',
    TYPE_ARTICLE: 'Статья',
    TYPE_NEWS: 'Новость',
    TYPE_EPISODE: 'Вечерка ТВ',
    TYPE_INFOGRAPHIC: 'Инфографика',
    TYPE_TREND: 'Темы',
    TYPE_BANNER: 'Баннеры',
    TYPE_MEANING: "Колонки",
    ARCHIVE_LIST: 'Запас',
    TYPE: 'Тип',
    CREATED_BY: 'Пользователь',
    ERROR_LOAD_ARCHIVE: 'Ошибка загрузки запаса',
    SEARCH_PLACEHOLDER: 'Название или slug',
    EDITOR_INSERT_MEDIA: 'Вставка',
    INSERT_PLUGIN: 'Вставка элементов в текст',
    INSERT_IMAGE: 'Изображение',
    INSERT_VIDEO: 'Видео',
    ERROR_LOAD_MEDIA: 'Ошибка загрузки media',
    INSERT: 'Вставить',
    MEDIA_TITLE: 'Заголовок',
    FILL_MEDIA_TITLE: 'Введите описание',
    MEDIA_SOURCE: 'Истоник',
    MEDIA_COPYRIGHT: 'Копирайт',
    MEDIA_COPYRIGHT_URL: 'Ссылка на источник',
    MEDIA_DELETE: 'Удалить',
    MEDIA_UPLOAD: 'Загрузить',
    EDIT_MEDIA: 'Редактирование медиа',
    SELECT_MEDIA: 'Выбрать из архива',
    MEDIA_SOURCES: 'Источники',
    ERROR_UPDATE_MEDIA: 'Ошибка обновления',
    INSERT_EXTERNALVIDEO: 'Внешнее видео',
    INCORRECT_SOURCE: 'Не известный источник',
    INSERT_CODE: 'Код',
    WIDTH: 'Ширина',
    HEIGHT: 'Высота',
    EMBEDDED: 'Код',
    EMBEDDED_EMPTY: 'Код не может быть пустым',
    ERROR_CREATE_CODE: 'Ошибка создания кода',
    VIDEOS: 'Видео',
    EVENTS: 'События',
    SERVICES: 'Услуги',
    PLACES: 'Места',
    FILMS: 'Фильмы',
    BOOKS: 'Книги',
    MUSIC: 'Музыка',
    ROUTES: 'Маршруты',
    PUBLISH_AT_RSS: 'Опубликовать в rss',
    EVENTS_TEXT: 'Описание события',
    EVENTS_PROPS: 'Свойства события',
    SELECT_DATE: 'Выберите дату',
    DATE_FROM_EVENTS: 'Дата начала',
    DATE_TO_EVENTS: 'Дата окончания',
    BUTTON_NAME: 'Надпись на кнопке',
    BUTTON_LINK: 'Ссылка на кнопке',
    CONTACTS_TEL: 'Контактный телефон',
    CONTACTS_EMAIL: 'Контактный email',
    CONTACTS_WEBSITE: 'Вебсайт',
    SERVICES_PROPS: 'Свойства услуги',
    PLACES_PROPS: 'Свойства места',
    PROPS: 'Свойства',
    LINK: 'Ссылка',
    TYPE_VIDEOS: 'Видео',
    TYPE_EVENTS: 'События',
    TYPE_SERVICES: 'Услуги',
    TYPE_PLACES: 'Места',
    TYPE_FILMS: 'Фильмы',
    TYPE_BOOKS: 'Книги',
    TYPE_MUSIC: 'Музыка',
    TYPE_ROUTES: 'Маршруты',
    EDIT_SHORT: 'Редактировать',
    MEDIA_MAX_WIDTH: 'Максимальная ширина',
    SELECT_WORKFLOW: 'выберите статус',
    ARCHIVE_NEWSPAPER: 'Архив газеты',
    NEWSPAPER: 'Газета',
    NEWSPAPER_EDIT: 'Редактирование газеты',
    NEWSPAPER_NUMBER: 'Номер выпуска',
    NEWSPAPER_NUMBER_PLACEHOLDER: 'Заполните номер выпуска',
    NEWSPAPER_NUMBER_PLACEHOLDER_FILL: 'Ошибка заполнения номера',
    NEWSPAPER_PUBLISHED: 'Дата выпуска',
    NEWSPAPER_TYPE: 'Типы выпуска',
    SELECT_NEWSPAPER_TYPE: 'Выберите тип выпуска',
    PDF_LABEL: 'Выберите pdf',
    DELETE_PDF: "Удалить pdf",
    NEWSPAPER_LIST: 'Список газет',
    REGION_NEWSPAPER: 'Газеты района',
    NEWSPAPER_REGION: 'Район газеты',
    RNEWSPAPER_LIST: 'Газеты района',
    RNEWSPAPER_EDIT: 'Редактирование',
    VIDEO_LABEL: 'Видео',
    SELECT_SHOW: 'Выберите категорию',
    DIRECTORY: 'Справочники',
    MATERIAL_AUTHORS: 'Авторы',
    BOOKS_AUTHORS: 'Авторы книг',
    LIST_BOOK: 'Список',
    BOOKS_GENRE: 'Жанры',
    BOOK_AUTHORS_LIST: 'Список авторов книг',
    BOOK_AUTHORS_EDIT: 'Редактирование автора книг',
    AUTHOR_COVER: 'Фотография',
    AUTHOR_NAME: 'Имя автора',
    BOOK_GENRE_LIST: 'Жанры для книг',
    BOOK_GENRE_EDIT: 'Редактирование жанра',
    BOOK_TEXT: 'Описание книги',
    BOOK_AUTHOR: 'Авторы книги',
    GENRE: 'Жанры',
    SELECT_GENRE: 'Выберите жанры',
    TYPE_BOOK_GENRE: 'Жанры книг',
    TYPE_FILMS_GENRE: 'Жанры фильмов',
    TYPE_MUSIC_GENRE: 'Жанры музыки',
    AUTHOR_QUOTE: 'Цитата автора о книгие',
    AUTHOR_QUOTE_PLACEHOLDER: 'Введите циту автора о его книге',
    BOOK_AUTHOR_COMMENT: 'Автор комментария',
    BOOK_AUTHOR_COMMENT_SELECT: 'Выберите автора комментария',
    AUTHOR_COMMENT_SUBTITLE: 'Подпись автора',
    BOOK_OTHER_MATERIAL: 'Материалы по теме',
    BOOK_OTHER_MATERIAL_SELECT: 'Выберите метариалы по теме',
    BOOK_MORE_TITLE: 'Заголовок на блоке подробнее',
    READ_MORE_BOOKS: 'Читайте также',
    SELECT_READ_MORE_BOOKS: 'Выберите книги',
    FILMS_LIST: 'Список',
    FILMS_AUTHORS: 'Режиссеры',
    FILMS_AUTHORS_LIST: 'Режиссеры',
    FILMS_GENRE: 'Жанры',
    FILMS_GENRE_LIST: 'Список жанров фильмов',
    FILMS_GENRE_EDIT: 'Редактирование жанра',
    FILMS_AUTHOR: 'Режиссеры',
    FILMS_AUTHOR_COMMENT: 'Автор комментария',
    FILMS_AUTHOR_COMMENT_SELECT: 'Выберите режиссера',
    FILM_TEXT: 'Описание фильма',
    VIEW_MORE: 'Смотрите также',

    MUSIC_LIST: 'Список',
    MUSIC_AUTHORS: 'Композиторы',
    MUSIC_AUTHORS_LIST: 'Композиторы',
    MUSIC_GENRE: 'Жанры',
    MUSIC_GENRE_LIST: 'Список жанров музыки',
    MUSIC_GENRE_EDIT: 'Редактирование жанра',
    MUSIC_AUTHOR: 'Композиторы',
    MUSIC_AUTHOR_COMMENT: 'Автор комментария',
    MUSIC_AUTHOR_COMMENT_SELECT: 'Выберите композитора',
    MUSIC_TEXT: 'Описание',
    MUSIC_EDIT: 'Редактирование',

    AUTHORIZATION_EXPIRED: 'Восстановление авторизации',
    YOUR_AUTHORIZATION_EXPIRED: 'Ваша авторизация истекла. Хотите продолжить?',
    RESUME: 'Продолжить',
    CURRENT_VERSION: 'Текущая версия',
    PREVIEW_VERSION: 'Предыдущая версия',
    YOU_ARE_USER_RECOVERY: 'Вы уверены, что хотите восстановить?',

    COMMENTS: 'Комментарии',
    PROFILES: 'Профили',
    PROFILES_LIST: 'Список',
    PROFILES_GROUP: 'Группы',
    PROFILES_PERMISSIONS: "Права",
    COMMENTS_LIST: 'Список комментариев',

    ERROR_LOAD_PROFILES: 'Ошибка загрузки профилей',
    PROFILE_LOGIN: 'Логин',
    PROFILE_CREATED: 'Дата регистрации',
    PROFILE_AVATAR: 'Аватар',
    PROFILE_EMAIL: 'Почта',
    PROFILE_NAME: 'Имя',
    PROFILE_ACTIVE: 'Активен',
    PROFILE_APPROVED: 'Подтвержден',
    PROFILE_TYPE: 'Тип',
    EDIT_PROFILE: 'Редактировать профиль',
    PROFILE_SEARCH_PLACEHOLDER: 'Логин/email/имя/фамилию профиля',
    PROFILE_STAGES: 'Этапы',
    PROFILE_SESSIONS: 'Сессии',

    ERROR_LOAD_GROUPS: 'Ошибка загрузки групп',
    GROUPS_SEARCH_PLACEHOLDER: 'Название/код группы',
    GROUPS_LIST: 'Список групп пользователей',
    CREATE_GROUP: 'Создать группу',

    PERMISSIONS_LIST: 'Список прав',
    CREATE_PERMISSION: 'Создать',
    PERMISSIONS_PLACEHOLDER: 'Код/название/описание права',
    ERROR_LOAD_PERMISSIONS: 'Ошибка загрузки прав',
    PERMISSION_CREATED: 'Право успешно создано',
    ERROR_CREATE_PERMISSION: 'Ошибка создания права. Право с этим кодом уже существует',
    PERMISSION_UPDATED: 'Право успешно обновлено',
    ERROR_UPDATE_PERMISSION: 'Ошибка обновления права',
    PERMISSION_CODE: 'Служебный идентификатор',
    PERMISSION_CODE_FILL: 'Заполните служебный идентификатор',
    PERMISSION_TITLE: 'Название',
    PERMISSION_TITLE_FILL: 'Заполните название права',
    PERMISSION_DESCRIPTION: 'Описание',
    DELETE_PERMISSION: 'Удалить право',
    EDIT_PERMISSION: 'Редактировать',
    DELETE_PERMISSION_REQUEST: 'Вы уверены, что хотите удалить право?',
    ERROR_DELETE_PERMISSION: 'Ошибка удаления права',
    SUCCESS_DELETE_PERMISSION: 'Право успешно удалено',
    UPDATE: 'Обновить',
    DESCRIPTION: 'Описание',
    GROUP_TITLE: 'Название группы',
    GROUP_PERMISSIONS: 'Права группы',
    GROUP_UPDATE_FINISH: 'Группа успешно обновлена',
    GROUP_UPDATE_ERROR: 'Ошибка обновления группы',
    GROUP_CREATE_FINISH: 'Группа успешно создана',
    GROUP_CREATE_ERROR: 'Ошибка создания группы',
    GROUP_DELETE_FINISH: 'Группа успешно удалена',
    GROUP_DELETE_ERROR: 'Ошибка удаления группы',
    DELETE_GROUP_REQUEST: 'Вы уверены, что хотите удалить группу?',
    DELETE_GROUP: 'удалить группу',
    EDIT_GROUP: 'редактировать группу',
    ACTIVE: 'Активна',
    PERMISSION: 'Права',
    GROUP_TITLE_FILL: 'Заполните название группы',
    UPDATE_GROUP: 'Обновление группы',
    PROFILE_EDIT: 'Редактирование профиля',
    ERROR_UPDATE_PROFILES: 'Ошибка обновления профиля, попробуйте пожалуйста позднее',
    PROFILES_UPDATED: `Профиль успешно обновлен`,
    ERROR_LOAD_PROFILE: 'Профиль не найден',
    PROFILE_BIRTHDAY: 'Дата рождения',
    PROFILE_ABOUT: 'Информация',
    PROFILE_ADDRESS: 'Район',
    PROFILE_LAST_NAME: 'Фамилия',
    PROFILE_PASSWORD: `Пароль`,
    PROFILE_GROUPS: 'Группы',
    PROFILE_INFO: 'Информация о профиле',
    PROFILE_SUBSCRIBES: 'Подписки профиля',
    THEATER: 'Театр',
    LIST_THEATER: 'Список',
    THEATER_TYPE: 'Тип',
    THEATER_TYPE_LIST: 'Типы выступлений',
    THEATER_LIST: 'Театр',
    SELECT_THEATER_TYPE: 'Выберите тип',
    THEATER_OTHER_MATERIAL: 'Материалы по теме',
    THEATER_MORE_TITLE: 'Заголовок на блоке cмотрите также',
    VIEW_OTHER_THEATER: 'Смотрите также',
    THEATER_OTHER_MATERIAL_SELECT: 'Выберите материалы',
    SELECT_VIEW_OTHER_THEATER: 'Выберите представления',
    TYPE_THEATER: 'Театр',
    TYPE_THEATER_TYPE: 'Типы театра',
    REPORTS: 'Отчеты',
    YANDEX: 'Яндекс',
    MATERIAL_STATS: 'Материалы',
    WIDGETS_STATS: 'Виджеты',
    BUDGET: 'Авторы',
    YANDEX_REPORT: 'Позиции в yandex',
    MATERIAL: 'Материалы',
    MATERIAL_REPORT: 'Отчет по материалам',
    OPINION_BATTLE: 'Битва мнений',
    BATTLE_LIST: 'Битва мнений',
    READ_TIME: "Время на чтение",
    OPINIONS: "Мнения",
    SELECT_OPINIONS: "Выберите мнения",
    WIDGETS_REPORT: "Отчет по виджетам",
    LOAD_REPORT: "Идет загрузка отчета",
    ERROR_LOAD_REPORT: "Ошибка загрузки отчета",
    VIEW_DOCS: 'Посмотреть документы',
    REPORT_TOTAL: 'Всего',
    REPORT_WEEK: '7 дней',
    REPORT_DAY: '24 часа',
    REPORT_HOUR: '1 час',
    PUBLISH_AT_GOOGLE: "Опубликовать в google",
    PUBLISH_AT_PULSE: 'Опубликовать в mail pulse',
    PUBLISH_AT_MIRROR: 'Опубликовать в yandex зеркало',
    ONLINE: 'Прямая трансляция',
    SCHEDULE: 'Расписание',
    SCHEDULE_LIST: 'Расписание',
    SCHEDULE_EDIT: 'Редактирование',
    DATE_START: 'Дата начала',
    DATE_END: 'Дата окончания',
    DATE: 'Дата',
    SORT: 'Сортировка',
    DISPLAY_TITLE: 'Отображаемый заголовок',
    EDIT_WIDGET_ITEM: 'Редактирование заголовка',
    DISPLAY_NAME: 'Отображаемое имя',
    CHAT: 'Чат',
    DELETE_CHAT_MESSAGE: 'Удалить сообщение',
    RESTORE_CHAT_MESSAGE: 'Восстановить сообщение',
    USER_BLOCKS: 'Блокировки пользователя',
    USER_BANNED_TO: 'Пользователь заблокирован на',
    SECONDS: 'Секунд',
    PUBLISH_AT_NEWSLINE: 'Отображать в новостное ленте',
    LIST_EVENTS: 'Список',
    EVENTS_TYPES: 'Типы событий',
    ETYPE_LIST: 'Типы событий',
    EVENTS_CITY: 'Города',
    EVENT_TYPE: 'Тип события',
    SELECT_EVENT_TYPE: 'Выберите тип события',
    EVENT_CITY: 'Город события',
    SELECT_EVENT_CITY: 'Выберите город события',
    WIDGETS_MAINPAGE: 'Главная страница',
    DOWNLOAD_XLS: 'Скачать XLS',
    EXPORT_TO: 'Экспортировать',
    MOVE_TYPE: 'Тип материала',
    ERROR_MOVE_TYPE: 'Ошибка обновления типа документа',
    TYPE_UPDATED: 'Документ успешно перенесен',
    REDIRECT_MESSAGE: 'Вы будете переадресованы через несколько секунд',
    ERROR_LOAD_SEARCH: 'Ошибка загрузки, повторите пожалуйста позднее',
    YES: 'Да',
    NO: 'Нет',
    NOT_PUBLISHED: 'Не опубликован',
    TYPE_SHOW: 'Рубрики вечерки',


    SENDING: 'Идет отправка',
    CANCEL: 'Отменена',
    SEND: 'Отправлена',
    PREPARE: 'Ожидает отправки',
    CREATE_EMAIL: 'Создание новой рассылки',
    EMAIL_RECOMMEND: 'Рекомендовать для рассылки',

    AUTHORS_RATES: 'Коэффиценты',
    AUTHORS_RATE: 'Коэффицент',
    ARATES_LIST: 'Коэффиценты',
    AUTHORS_VIEWS: 'Гонорары',
    AVIEWS_LIST: 'Гонорары',
    RATE: 'Ставка',
    ARATES_EDIT: 'Коэффиценты',
    VIEWS_FROM: 'Просмотры от',
    VIEWS_TO: 'Просмотры до',
    FEE: 'Гонорар',
    VIDEO_REPORT: 'Отчет по видео',
    EMBARGO: 'Эмбарго',
    PUBLISH_AT_WIFI: 'Опубликовать в wifi',
    WIDGETS_EDITOR: 'Редакторские',
    WIDGETS_ADS: 'Рекламные',
    MAIN_MATERIAL: 'Главный материал',
    SELECT_MAIN_MATERIAL: 'Выберите главный материал',

    UNPUBLISH_ACTION: 'Распубликовать',
    PUBLISH_ACTION: 'Опубликовать',
    PUBLISH_POPCONFIRM: 'Вы уверены, что хотите опубликовать этот материал?',

    PUSH: 'Push рассылка',
    SEND_PUSH: 'Отправить push',
    PUSH_SEND: 'Push рассылка успешно отправлена',
    PUSH_SEND_ERROR: 'Ошибка отправки рассылки',
    POPULAR_MATERIAL: 'Знак качества',
    MAINPAGE_PUBLISH: 'Публикация на главной странице',
    MAINPAGE_FIELD: 'Главная',

    STAGES: 'Этапы',

    SUBMITS_LIST: 'Заявки',
    SUBMIT_CREATED: 'Дата заявки',
    SUBMIT_EMAIL: 'Почта',
    EDUCATION: 'Образование',
    SUBMIT_COURSE: 'Курс',
    SUBMIT_TEXT: 'Сопроводительное письмо',
    SUBMIT_FILE: 'Прикрепленный файл',
    NEW: 'Новая',
    EXISTING: 'Зарегестрирован',
    NOT_APPROVED: 'Неподтвержден',
    APPROVED: 'Подтвержден',
    DECLINED: 'Отклонен',
    SUBMIT_APPLY: 'Принять',
    SUBMIT_DECLINE: 'Отклонить',
    SUBMIT_ACCEPT_SUCCESS: 'Заявка успешно принята',
    SUBMIT_ACCEPT_FAILED: 'Ошибка подтверждения заявки',
    SUBMIT_MESSAGE_SEND: 'Подтверждающее письмо отправлено',
    SUBMIT_MESSAGE_ERROR: 'Ошибка отправки письма с подтверждением',
    SUBMIT_DECLINE_SUCCESS: 'Заявка успешно отклонена',
    SUBMIT_DECLINE_ERROR: 'Ошибка удаления заявки',
    ONE_SUBMIT: 'Заявка',

    COURSES_LIST: 'Курсы',
    COURSE_ACTIVE: 'Активен',
    COURSE_TITLE: 'Название',
    COURSE_NAME_FILL: 'Заполните название',
    COURSE_TEXT: 'Описание',
    COURSE_TEXT_FILL: 'Заполните описание',
    COURSE_DATES: 'Даты проведения',
    COURSE_DATES_FILL: 'Заполние даты проведения',
    COURSE_DATES_SUBMIT: 'Даты приема заявок',
    COURSE_DATES_SUBMIT_FILL: 'Заполните даты приема заявок',
    COURSE_MEDIA: 'Материялы',
    COURSE_STAGES: 'Этапы',
    COURSES_SEARCH_PLACEHOLDER: 'Поиск по названию',
    COURSE_INFO: 'Информация о курсе',
    COURSE_SAVE: 'Сохранить',
    COURSE_NEW_BTN: 'Создать',
    COURSE_CREATE: 'Новый курс',
    COURSE_CREATE_ERROR: 'Ошибка создания курса',
    COURSE_EDIT: 'Изменение курса',
    ERROR_LOADING_COURSES: 'Не удалось загрузить курс',
    COURSE_UPDATE_ERROR: 'Не удалось обновить курс',
    COURSE_UPDATE_FINISH: 'Курс успешно обновлен',
    COURSE_SUBMITS: 'Прием заявок',
    COURSE_SHOW_DESCRIPTION: 'Показать',
    COURSE_DESCRIPTION: 'Описание',
    COURSE_EMPTY_DESCRIPTION: 'Не заполнено',
    COURSE_PREVIEW: 'Превью курса',

    STAGE_ADD: 'Новый этап',
    STAGE_COVER: 'Обложка',
    STAGE_TITLE: 'Название',
    STAGE_DATES: 'Период проведения',
    STAGE_COURSE: 'Курс',
    STAGE_TEXT: 'Описание',
    STAGE_HOMEWORK: 'Домашнее задание',
    EDIT_STAGE: 'Внести изменения',
    STAGES_LIST: 'Этапы',
    STAGE_NEW_BTN: 'Добавить этап',
    STAGE_CREATE_ERROR: 'Ошибка при создании этапа',
    STAGE_DATES_FILL: 'Выберете даты проведения этапа',
    STAGE_UPDATE_FINISH: "Этап успешно обновлен",
    STAGE_UPDATE_ERROR: "Ошибка обновления данных этапа",
    SHOW_STAGE_TEXT: 'Показать описание',
    SHOW_STAGE_HOMEWORK: 'Показать домашнее задание',

    LESSONS_ACTIVE: 'Активен',
    LESSONS_TITLE: 'Название',
    LESSONS_DATES: 'Даты проведения',
    LESSONS_STAGE: 'Этап',
    LESSONS_MEDIA: 'Матерьялы',
    EDIT_LESSON: 'Изменить',
    LESSONS_LOAD_ERROR: 'Ошибка загруки уроков',
    LESSONS_UPDATE_ERROR:'Ошибка при внесении изменений',
    LESSONS_UPDATE_FINISH:'Урок успешно обновлен',
    LESSON_NEW_BTN: 'Новый урок',
    LESSONS_SEARCH_PLACEHOLDER: 'Искать по названию',
    LESSON_CREATE_ERROR: 'Ошибка создания урока',
    LESSON_NOT_FOUND: 'Урок не найден',
    LOADING_LESSON: 'Загрузка урока',
    LESSON_NAME_FILL: 'Заполните название',
    LESSON_DATES: 'Даты проведения',
    LESSON_DATES_FILL: 'Заполните даты проведения',
    LESSON_STAGE: 'Этап',
    LESSON_DESCRIPTION: 'Описание',
    LESSON_TEACHER: 'Преподаватель',
    LESSON_TEXT: 'Текст урока',
    LESSONS_LIST: 'Уроки',
    LESSON_NUMBER: 'Номер урока',
    LESSON_NUMBER_PLACEHOLDER: 'Порядковый номер урока',
    LESSON_NUMBER_FILL: 'Заполните номер урока',
    LESSON_MATERIALS: 'Использованные материалы',
    LESSON_NEW_MARK: 'Лебл NEW',

    HOMEWORKS_LIST: 'Домашние задания',
    HOMEWORK_VISIBLE: 'Видят все',
    HOMEWORK_PROFILE: 'Профиль ученика',
    HOMEWORK_TEACHER: 'Учитель',
    HOMEWORK_TEACHER_COMMENT: 'Комментарий учителя',
    HOMEWORK_TEXT: 'Текст',
    HOMEWORK_FILES: 'Файлы',
    HOMEWORK_MARK: 'Оценка',
    HOMEWORK_SEARCH_PLACEHOLDER: 'Поиск',
    HOMEWORK_LESSON: 'Урок',
    HOMEWORK_FILTER_BY_STUDENT: 'Ученик',
    HOMEWORK_FILTER_BY_STAGE: 'Этап', 

    TEACHERS_LIST: 'Учителя',

    WIDGET_SUBMITS: 'Последние заявки',
    WIDGET_HOMEWORK: 'Последние ДЗ',
    STUDENTS: 'Ученики',
    STAGES_MARKS: 'Критерии оценки',

    ADD_MARK: 'Добавить критерий',
    MARK_TITLE: 'Название',
    MARK_VALUE: 'Макс. балл',
    TOTAL_VALUE: 'Общее число баллов',
    HOMEWORK_STAGE: 'Этап',
    VIEW_HOMEWORK: 'Посмотреть',

    HOMEWORK_LOAD_ERROR: 'Ошибка загрузки домашних работ',
    PROFILE_HOME_WORK: 'Домашняя работа пользователя',
    FOR_STAGE: 'для этапа',
    STUDENT: 'Студент',
    STAGE: 'Этап',
    MARK: 'Оценка',
    TOTAL_UPLOADS: 'Обновлений',
    TEACHER_CHECK: 'Проверил',
    NOT_CHECK: 'Не проверено',
    CHECK: `Проверить`,
    TEACHER_PHOTO: 'Фото',
    TEACHER_NAME: 'Имя',
    TEACHER_TITLE: 'Должность',
    TEACHER_DESCRIPTION: 'Описание',
    TEACHERS_NEW_BTN: 'Новый учитель',
    TEACHERS_SEARCH_PLACEHOLDER: 'Поиск по имени',
    TEACHER_CREATE_ERROR: 'Невозможно создать учителя',
    TEACHER_NOT_FOUND: 'Учитель не найден',
    LOADING_TEACHER: 'Загрузка данных учителя',
    DELETE_TEACHER: 'Удалить учителя',
    TEACHER_EDIT: 'Редактирование учителя',
    TEACHER_LAST_NAME_FILL: 'Заполните фамилию учителя',
    TEACHER_NAME_FILL: 'Заполните имя учителя',
    TEACHER_TITLE_PLACEHOLDER: 'Заполните должнисть учителя',

    ERROR_LOAD_HOMEWORK_PROFILE: 'Ошибка загрузки домашней работы',
    USER_NAME: `ФИ`,
    USER_LOGIN: `Логин`,
    USER_EMAIL: `Email`,
    USER_VIEW_PROFILE: `Посмотреть профиль`,
    STUDENT_PROFILE: 'Профиль студента',
    STUDENT_HOMEWORK: 'Загруженная работа',
    HOMEWORK_CRITERIA: 'Оценка',
    UPLOADED_DATE: 'Дата загрузки',
    MAX_CRITERIA_VALUE: 'Максимальный бал',
    MAX_CRITERIA: `Максимальный бал`,
    HOMEWORK_STATUS: 'Статус',
    HSTATUS_CREATED: 'Создана',
    HSTATUS_REJECTED: 'Отклонена',
    HSTATUS_CHECKED: 'Проверена',
    HOMEWORK_HAS_BEEN_UPDATED: 'Работа успешно обновлена',
    ERROR_UPDATE_HOMEWORK: 'Ошибка обновления',
    ADD_COMMENT: 'Добавить комментарий',
    UPLOAD_FILE_TEXT: 'Загрузить файл',
    ERROR_UPLOAD_FILE: 'Ошибка загрузки файла',
    FILE_UPLOADED_SUCCESS: "Файл загружен успешно",
    REPLIES: ['Сообщение', 'Сообщения', 'Сообщений'],
    COMMENT_CREATED: 'Комментарий успешно создан',
    COMMENT_CREATE_ERROR: 'Ошибка создания комментария',
    PUBLIC_NAME: 'Публичное имя',
    NAME: 'Имя',
    LAST_NAME:'Фамилия',
    PUBLIC_NAME_PLACEHOLDER: 'Публичное имя',

    WELCOME_GROUP: 'Блок приветствия',
    WELCOME_TEXT: 'Текст добро пожаловать',
    WELCOME_TITLE: 'Заголовок для приветствия',

    NOTIFICATION_LIST: 'Список уведомлений',
    ERROR_LOAD_NOTIFICATIONS: 'Ошибка загрузки уведомлений',
    SEND_EMAIL: 'Отправить на почту',
    PROFILE_LANGUAGE: 'Язык',
    PROFILE_PHONE: 'Телефон',
    PROFILE_COUNTRY: 'Страна',
    PROFILE_CITY: 'Город',
    NOT_CHECKED: 'Не проверено',
    HOMEWORK_CHECKED: 'Проверена',
    HOMEWORK_REJECTED: 'Отклонена',
    HOMEWORK_CREATED: 'Создана',

    WEBINARS_LIST: "Вебинары",
    WEBINARS_PUBLISHED: "Опубликован",
    WEBINAR_DATE: "Дата проведения",
    WEBINARS_TITLE: "Название",
    WEBINAR_STAGE: "Этап",
    WEBINARS_DATE: "Дата",
    WEBINAR_EDIT: "Редактирование",
    WEBINAR_ADD: "Новый вебинар",
    WEBINARS_NEW_BTN: "Добавить",
    WEBINARS_SEARCH_PLACEHOLDER: "Поиск по названию",
    WEBINAR_NOT_FOUND: "Вебинар не найден",
    LOADING_WEBINAR: "Загрузка",
    WEBINAR_TITLE_FILL: "Заполните название",
    WEBINAR_STAGE_FILL: "Выберите этап",
    WEBINAR_TEXT: "Описание + ссылка",

    NOTIFICATION_CREATED_AT: 'Дата создания',
    NOTIFICATION_TEXT: 'Сообщение',
    NOTIFICATION_LINK: 'Ссылка',
    NOTIFICATION_PROFILES_LIST: 'Профили',
    NOTIFICATIONS_STAGES: 'Этапы',
    NOTIFICATIONS_STAGES_PLACEHOLDER: 'Оставьте пустым для рассылки по всем этапам',
    NOTIFICATIONS_ONLY_APPROVED: 'Рассылка только для неподтвержденных пользователей',
    NOTIFICATION_SUBJECT: 'Тема письма',
    NOTIFICATION_MESSAGE: 'Текст письма',
    NOTIFICATION_LOAD_ERROR: 'Ошибка загрузки рассылок',
    NOTIFICATION_SEND_SUCCESS: 'Рассылка успешно отправлена',
    NOTIFICATION_SEND_ERROR: 'Ошибка отправки писем',
    NOTIFICATION_CREATE: 'Создать рассылку',
    APPROVED_COUNT: 'Подтвержденных:', 
    NOT_APPROVED_COUNT: 'Не подтверженных:',
    NOTIFICATION_SEND: 'Отправить',
    NOTIFICATION_CLOSE: 'Закрыть',

    PREREQESTS_TITLE: 'Уведомления о старте курса',
    PREREQUEST_FOR: 'Оповещение для курса',
    PREREQUEST_TEXT: 'Текст письма',
    PREREQUEST_SUBSCRIBERS_COUNT: 'Подписчиков',

    MAILING: "Рассылки",
    PREREQUESTS_LIST: "Cтарт нового курса",
    STAGES_MAILING: "Для идущих курсов"
};

export default RU;
