import { combineReducers } from 'redux';
import ProfilesReducer from './profiles/reducer';
import SubmitsReducer from './submits/reducer';
import CoursesReducer from './courses/reducer';
import MediaReducer from  './media/reducer';
import StagesReducer from  './stages/reducer';
import LessonsReducer from './lessons/reducer';
import HomewoksReducer from './homeworks/reducer';
import TeachersReducer from './teachers/reducer';
import NotificationsReducer from './notifications/reducer';
import WebinarsReducer from './webinars/reducer';
import PreRequestsReducer from './prerequests/reducer';
import MailingReducer from './mailing/reducer';

export default combineReducers({
    ProfilesReducer,
    SubmitsReducer,
    CoursesReducer,
    MediaReducer,
    StagesReducer,
    LessonsReducer,
    HomewoksReducer,
    TeachersReducer,
    NotificationsReducer,
    WebinarsReducer,
    PreRequestsReducer,
    MailingReducer
});
