export const EN = {
    LOADING: 'Loading',
    DASHBOARD: 'Dashboard',
    CATEGORIES: 'Categories',
    TAGS: 'Tags',
    TRENDS: 'Trends',
    BANNERS: 'Banners',
    WIDGETS: 'Widgets',
    PAGES: 'Pages',
    USERS: 'Users',
    DOCUMENTS: 'Documents',
    ARTICLE: 'Article',
    EPISODE: 'Episode',
    GALLERY: 'Gallery',
    INFOGRAPHIC: 'Infographic',
    AUTHORS: 'Authors',
    GROUPS: 'Groups',
    PERMISSIONS: 'Permissions',
    PROFILE: 'Profile',
    LOGOUT: 'Logout',
    NO_EVENTS: 'no events for you',
    WELCOME_MESSAGE: 'Welcome to admin panel',
    DASHBOARD_WELCOME: 'Welcome to admin panel',
    ARTICLE_LIST: 'Articles list',
    EPISODE_LIST: 'Episodes list',
    GALLERY_LIST: 'Gallery list',
    INFOGRAPHIC_LIST: 'Infographics list',
    LOADING_DOCUMENTS: 'Documents loading',
    EDIT: 'edit document',
    VIEW: 'preview document',
    DELETE: 'delete document',
    CROSSLINKS: 'crosslinks',
    SHOW_ALL_TAGS: 'show all tags',
    HIDE_ALL_TAGS: 'hide all tags',
    IMAGE: 'image',
    COVER: 'cover',
    VIDEO: 'video',
    EXTERNALVIDEO: 'external video',
    CODE: 'code',
    CATEGORY: "Category",
    AUTHOR: 'Author',
    PUBLISHED: 'published',
    UNPUBLISHED: 'unpublished',
    PREPUBLISHED: 'prepublished',
    CREATE: 'Create',
    TITLE_OR_URL: 'title or url',
    SELECT_STATUS: 'select status',
    SELECT_TAGS: 'select tags',
    SELECT_CATEGORY: 'select category',
    DATE_FROM: 'date from',
    DATE_TO: 'date till',
    ALL: 'all',
    EMPTY_DOCUMENTS: 'No documents',
    CLEAR_FILTER: 'clear_filter',
    CREATED: 'Created',
    UPDATED: 'Updated',
    AT: 'at',
    SURE_DELETE: 'You are sure that you want to delete this document?',
    CLOSE: 'Close',
    NOT_SET: '[not set]',
    ARTICLE_ADD: 'Add article',
    UPLOAD_TEXT: 'Click or drag file to this area to upload',
    COVER_LABEL: 'Cover',
    UPLOAD_NEW: 'New image',
    UPLOAD_SEL: 'Select image',

    NEWS: 'News',
    ARTICLES: 'Articles',
    TITLE: 'Title',
    UPDATE_DOCUMENT: 'Save',
    DOC_STATUS: 'Status',
    CREATE_BY: 'Created by',
    UPDATED_BY: 'Updated',
    HISTORY: 'History',
    SUMMARY: 'Summary',
    DELETE_MEDIA: 'Delete cover',
    TEXT: 'Document text',
    SLUG: 'Slug',
    PUBLISHED_AT: 'Publish',
    COORDS: 'Coordinates',
    COORDS_HELP: 'Enter coordinates',
    HIDE_MAP: 'Hide map',
    SHOW_MAP: 'Show map',
    GENERATE: 'Generate',
    PUBLISH_IN: 'Publish in',
    SUB_CATEGORY: 'In category',
    VIEW_FIELD: 'View',
    SEO_TITLE: 'Seo title',
    SEO_DESCRIPTION: 'Seo description',
    REDIRECT_TO: 'Redirect to',
    SEO_AND_VIEW: 'View settings',
    published: 'Published',
    DOCUMENT_HISTORY_EVENT: 'Document history',
    RECOVER: 'Recover',
    TITLE_ERROR_FILL: 'Enter document title',
    SUMMARY_ERROR_FILL: 'Fill document summary',
    SLUG_PLACEHOLDER: 'Enter document slug',
    SLUG_ERROR: 'Fill document slug',
    SELECT_CATEGORIES: 'Select document category',
    SELECT_AUTHORS_ERROR: 'Select document author',
    AUTHORS_PLACEHOLDER: 'Select document author',
    TITLE_PLACEHOLDER: 'Enter title',
    SUMMARY_PLACEHOLDER: 'Enter summary',
    SELECT_SUB_CATEGORIES: 'Select sub category',
    SELECT_TRENDS: 'Select trend',
    SELECT_CROSSLINKS: 'Select crosslinks',
    NO_HISTORY: 'History is empty',
    ARTICLE_EDIT: 'Article edit',
    EPISODE_ADD: 'Add episode',
    EPISODE_EDIT: 'Episode edit',
    COORDS_MAP_HELP: 'Select point by click on map ',
    GALLERY_EDIT: 'Edit gallery',
    CREATE_BTN: 'Create',
    PUBLIC_ID: 'ID',
    CREATED_AT: 'Created at',
    UPDATED_AT: 'Updated at',
    ACTIONS: 'Actions',
    CREATE_DOCUMENT: 'Create document',
    DOCUMENT_UPDATE_ERROR: 'Update document error',
    DOCUMENT_UPDATE_SUCCESS: 'Document successfully updated',
    DOCUMENT_DELETED: 'Document successfully removed',
    DOCUMENT_DELETE_ERROR: 'Remove document error',
    DOCUMENTS_LOAD_ERROR: 'Document load error',
    REPEAT_AGAIN_LATER: 'Try again laiter',
    DOCUMENT_UPDATED: 'Document successfully updated',
    LOGIN: 'Login',
    AUTHORIZATION_WAIT: 'Wait until authorization complete',
    AUTHORIZATION_ERROR: 'Authorization error',
    CLEAR: 'Clear',
    APPLY: 'Apply',
    SEARCH_MEDIA_PLACEHOLDER: 'Title or copyright',
    REMOVE: 'Remove',
    ADD: 'Add',
    SOURCE: 'Source',

    REMOVE_ALL: 'Remove all',
    SAVE_ALL: 'Save all',
    UPLOAD_FIRST_ROW: 'Click or drag file for upload',
    UPLOAD_NEXT_ROW: ' ',
    MEDIA: 'Media',
    UPLOAD: 'Upload',
    SEARCH: 'Search',
    MEDIA_LIST: 'Media list',
    WIDGET_ADD_TITLE: 'Add widget',
    EDIT_WIDGET: 'Edit widget',
    VIEW_WIDGET: 'Preview widget',
    WIDGET_UPDATED: 'Widget updated successfully',
    WIDGET_CREATED: 'Widget created successfully',
    MAX_SIZE: 'Max documents count',
    DOCS_SIZE: 'Documents count',
    DOC_TYPES: 'Document types',
    ADMIN_TITLE: 'Заголовок (админка)',
    WIDGETS_LIST: 'Widgets list',
    SAVE: 'Save',
    WIDGET_NOT_FOUND: 'Widget not found',
    MAXIMUM_ELEMENT_IN_WIDGET: 'Count of documents in widget',
    WIDGETS_EDIT: 'Widget edit',
    TAG_LIST: 'Tags list',
    CATEGORY_LIST: 'Categories list',
    AUTHORS_LIST: 'Authors list',
    PAGES_LIST: 'Pages list',
    HREF: 'Link',
    TEXT_PLACEHOLDER: 'Document text',
    REDIRECT_TO_ERROR: 'Redirect field is entered incorrect',
    SLUG_FILL_ERROR: 'Slug field us entered incorrect',
    PAGE_LIST: 'Pages list',
    BANNER_LIST: 'Banners list',
    TREND_LIST: 'Topics list',
    TREND_ADD: 'Add topic',
    TREND_EDIT: 'Edit topic',
    BANNER_ADD: 'Add banner',
    BANNER_EDIT: 'Edit banner',
    OTHER: 'Other',
    PREVIEW: 'Preview',
    WIDGET_TITLE: 'Widget title',
    ADMIN_WIDGET_TITLE: 'Widget title (admin)',
    AUTHOR_LIST: 'Authors list',
    DOCUMENT_CREATE_FINISH: 'Document successfully created',
    AUTHOR_EDIT: 'Edit author',
    AUTHOR_ADD: 'Create new author',
    LOADING_DOCUMENT: 'Loading document',
    

    AUTHORIZATION_EXPIRED: 'Authorization expired',
    YOUR_AUTHORIZATION_EXPIRED: 'Your authorization expired. Will you continue?',
    RESUME: 'Continue',
   
    YES: 'Да',
    NO: 'Нет',
    NOT_PUBLISHED: 'Not published',

    EDITOR_INSERT_MEDIA: 'Insert media',
    INSERT_PLUGIN: 'Insert to text',
    INSERT_IMAGE: 'Image',
    INSERT_VIDEO: 'Video',
    ERROR_LOAD_MEDIA: 'Error load media',
    INSERT: 'Insert',
    MEDIA_TITLE: 'Title',
    FILL_MEDIA_TITLE: 'Enter description',
    MEDIA_SOURCE: 'Source',
    MEDIA_COPYRIGHT: 'Copyright',
    MEDIA_COPYRIGHT_URL: 'Link to source',
    MEDIA_DELETE: 'Delete',
    MEDIA_UPLOAD: 'Upload',
    EDIT_MEDIA: 'Edit media',
    SELECT_MEDIA: 'Select from archive',
    MEDIA_SOURCES: 'Sources',
    ERROR_UPDATE_MEDIA: 'Update failed',
    INSERT_EXTERNALVIDEO: 'External video',
    INCORRECT_SOURCE: 'Unknown source',
    INSERT_CODE: 'Code',
    WIDTH: 'Width',
    HEIGHT: 'Height',
    EMBEDDED: 'Embed code',
    EMBEDDED_EMPTY: 'Embed code cannot be empty',
    ERROR_CREATE_CODE: 'Error at creating embed code',
    SEARCH_PLACEHOLDER: 'Search',

    SENDING: 'Sending',
    CANCEL: 'Cancel',
    SEND: 'Sent',
    PREPARE: 'Prepared',
    CREATE_EMAIL: 'Create new mailing list',
    ACTIVE: 'Active',

    STAGES: 'Stages',
    PROFILES: 'Profiles',
    PROFILE_NAME: 'First name',
    STATUS: 'Status',
    PROFILE_AVATAR: 'Avatar',
    PROFILE_APPROVED: 'Approved',
    PROFILE_ACTIVE: 'Active',
    PROFILE_LOGIN: 'Login',
    PROFILE_EMAIL: 'Email',
    PROFILE_CREATED: 'Created at',
    PROFILE_LAST_NAME: 'Last name',
    PROFILE_PASSWORD: 'Password',
    PROFILE_BIRTHDAY: 'Birthday',
    PROFILE_ABOUT: 'About',
    PROFILE_STAGES: 'Stages',
    PROFILE_INFO: 'Student profile',
    PROFILE_SEARCH_PLACEHOLDER: 'Search by requests',

    SUBMITS_LIST: 'Requests',
    SUBMIT_CREATED: 'Request date',
    SUBMIT_EMAIL: 'Email',
    EDUCATION: 'Education',
    SUBMIT_COURSE: 'Course',
    SUBMIT_TEXT: 'Cover letter',
    SUBMIT_FILE: 'Attached file',
    NEW: 'New',
    EXISTING: 'Registered',
    NOT_APPROVED: 'Not approved',
    APPROVED: 'Approved',
    DECLINED: 'Declined',
    SUBMIT_APPLY: 'Apply',
    SUBMIT_DECLINE: 'Decline',
    SUBMIT_ACCEPT_SUCCESS: 'Request accepted',
    SUBMIT_ACCEPT_FAILED: 'Request acceptance error',
    SUBMIT_MESSAGE_SEND: 'Confirmation mail was sent',
    SUBMIT_MESSAGE_ERROR: 'Confirmation email sent error',
    SUBMIT_DECLINE_SUCCESS: 'Request declined successfully',
    SUBMIT_DECLINE_ERROR: 'Request remove error',
    ONE_SUBMIT: 'Request',

    COURSES_LIST: 'Courses',
    COURSE_ACTIVE: 'Active',
    COURSE_TITLE: 'Title',
    COURSE_NAME_FILL: 'Fill the title',
    COURSE_TEXT: 'Description',
    COURSE_TEXT_FILL: 'Fill the description',
    COURSE_DATES: 'Course dates',
    COURSE_DATES_FILL: 'Fill the course dates',
    COURSE_DATES_SUBMIT: 'Requests acceptance dates',
    COURSE_DATES_SUBMIT_FILL: 'Fill the acceptance dates',
    COURSE_MEDIA: 'Media',
    COURSE_STAGES: 'Stages',
    COURSES_SEARCH_PLACEHOLDER: 'Search by title',
    COURSE_INFO: 'Course info',
    COURSE_SAVE: 'Save',
    COURSE_NEW_BTN: 'Create',
    COURSE_CREATE: 'New Course',
    COURSE_CREATE_ERROR: 'Course creation error',
    COURSE_EDIT: 'Edit course',
    ERROR_LOADING_COURSES: 'Error while loading the course',
    COURSE_UPDATE_ERROR: 'Error while course update',
    COURSE_UPDATE_FINISH: 'Course successfully updated',
    COURSE_SUBMITS: 'Request acceptance',
    COURSE_SHOW_DESCRIPTION: 'Show',
    COURSE_DESCRIPTION: 'Description',
    COURSE_EMPTY_DESCRIPTION: 'Not filled',
    COURSE_PREVIEW: 'Course preview',

    STAGE_ADD: 'New stage',
    STAGE_COVER: 'Cover',
    STAGE_TITLE: 'Tilte',
    STAGE_DATES: 'Stage dates',
    STAGE_COURSE: 'Course',
    STAGE_TEXT: 'Description',
    STAGE_HOMEWORK: 'Homework task',
    EDIT_STAGE: 'Edit',
    STAGES_LIST: 'Stages list',
    STAGE_NEW_BTN: 'Add stage',
    STAGE_CREATE_ERROR: 'Stage creation error',
    STAGE_DATES_FILL: 'Select stage dates',
    STAGE_UPDATE_FINISH: "Stage successfully updated",
    STAGE_UPDATE_ERROR: "Stage update error",
    SHOW_STAGE_TEXT: 'Show description',
    SHOW_STAGE_HOMEWORK: 'Show homework',
    STAGE_EDIT: 'Edit',

    LESSONS_ACTIVE: 'Active',
    LESSONS_TITLE: 'Title',
    LESSONS_DATES: 'Dates of the event',
    LESSONS_STAGE: 'Stage',
    LESSONS_MEDIA: 'Media',
    EDIT_LESSON: 'Edit',
    LESSONS_LOAD_ERROR: 'Lessons load error',
    LESSONS_UPDATE_ERROR:'Lessons update error',
    LESSONS_UPDATE_FINISH:'Lesson successfully updated',
    LESSON_NEW_BTN: 'New lesson',
    LESSONS_SEARCH_PLACEHOLDER: 'Search by title',
    LESSON_CREATE_ERROR: 'New lesson creation error',
    LESSON_NOT_FOUND: 'Lesson not found',
    LOADING_LESSON: 'Load lesson',
    LESSON_NAME_FILL: 'Enter the title',
    LESSON_DATES: 'Dates',
    LESSON_DATES_FILL: 'Select the lesson dates',
    LESSON_STAGE: 'Stage',
    LESSON_DESCRIPTION: 'Description',
    LESSON_TEACHER: 'Teacher',
    LESSON_TEXT: 'Lesson text',
    LESSONS_LIST: 'Lessons list',
    LESSON_NUMBER: 'Lesson number',
    LESSON_NUMBER_PLACEHOLDER: 'Lesson order number',
    LESSON_NUMBER_FILL: 'Enter lesson number',
    LESSON_MATERIALS: 'Used materials',
    LESSON_NEW_MARK: 'New mark',

    HOMEWORKS_LIST: 'Homeworks list',
    HOMEWORK_VISIBLE: 'Visible for all',
    HOMEWORK_PROFILE: 'Student profile',
    HOMEWORK_TEACHER: 'Teacher',
    HOMEWORK_TEACHER_COMMENT: 'Teacher comment',
    HOMEWORK_TEXT: 'Text',
    HOMEWORK_FILES: 'Files',
    HOMEWORK_MARK: 'Score',
    HOMEWORK_SEARCH_PLACEHOLDER: 'Search',
    HOMEWORK_LESSON: 'Lesson',
    HOMEWORK_FILTER_BY_STUDENT: 'Student',
    HOMEWORK_FILTER_BY_STAGE: 'Stage',

    TEACHERS_LIST: 'Teachers list',

    WIDGET_SUBMITS: 'Last requests',
    WIDGET_HOMEWORK: 'Last homeworks',
    STUDENTS: 'Students',
    STAGES_MARKS: 'Score criteria',

    ADD_MARK: 'Add criteria',
    MARK_TITLE: 'Title',
    MARK_VALUE: 'Max score',
    TOTAL_VALUE: 'Total score',
    HOMEWORK_STAGE: 'Stage',
    VIEW_HOMEWORK: 'View',

    HOMEWORK_LOAD_ERROR: 'Error while loading homeworks',
    PROFILE_HOME_WORK: 'Studnt homework',
    FOR_STAGE: 'For stage',
    STUDENT: 'Student',
    STAGE: 'Stage',
    MARK: 'Score',
    TOTAL_UPLOADS: 'Total uploads',
    TEACHER_CHECK: 'Checked by',
    NOT_CHECK: 'Not checked',
    CHECK: `Check`,
    TEACHER_PHOTO: 'Photo',
    TEACHER_NAME: 'Name',
    TEACHER_TITLE: 'Title',
    TEACHER_DESCRIPTION: 'Description',
    TEACHERS_NEW_BTN: 'New teacher',
    TEACHERS_SEARCH_PLACEHOLDER: 'Search by name',
    TEACHER_CREATE_ERROR: 'Error while creating teacher',
    TEACHER_NOT_FOUND: 'Teacher not found',
    LOADING_TEACHER: 'Loading teacher data',
    DELETE_TEACHER: 'Remove teacher',
    TEACHER_EDIT: 'Edit teacher',
    TEACHER_LAST_NAME_FILL: 'Enter the teacher surname',
    TEACHER_NAME_FILL: 'Enter the teacher name',
    TEACHER_TITLE_PLACEHOLDER: 'Enter the teacher title',
    AUTHUSER_LINK: 'Link to user profile',
    NAME_PLACEHOLDER: 'Enter the teacher name',
    LAST_NAME_PLACEHOLDER: 'Enter the teacher last name',

    ERROR_LOAD_HOMEWORK_PROFILE: 'Error while loading homework',
    USER_NAME: `Name/Surname`,
    USER_LOGIN: `Login`,
    USER_EMAIL: `Email`,
    USER_VIEW_PROFILE: `View profile`,
    STUDENT_PROFILE: 'Student profile',
    STUDENT_HOMEWORK: 'Uploaded homework',
    HOMEWORK_CRITERIA: 'Score',
    UPLOADED_DATE: 'Upload date',
    MAX_CRITERIA_VALUE: 'Max score',
    MAX_CRITERIA: `MAx score`,
    HOMEWORK_STATUS: 'Status',
    HSTATUS_CREATED: 'Created',
    HSTATUS_REJECTED: 'Declined',
    HSTATUS_CHECKED: 'Checked',
    HOMEWORK_HAS_BEEN_UPDATED: 'Homework successfully updated',
    ERROR_UPDATE_HOMEWORK: 'Error while update homework',
    ADD_COMMENT: 'Add comment',
    UPLOAD_FILE_TEXT: 'Upload file',
    ERROR_UPLOAD_FILE: 'File upload error',
    FILE_UPLOADED_SUCCESS: "File uploaded successfully",
    REPLIES: ['Message', 'Messages', 'Messages'],
    COMMENT_CREATED: 'Comment successfully created',
    COMMENT_CREATE_ERROR: 'Error while creating comment',
    PUBLIC_NAME: 'Public name',
    NAME: 'Name',
    LAST_NAME:'Surname',
    PUBLIC_NAME_PLACEHOLDER: 'Public name',

    WELCOME_GROUP: 'Welcome block',
    WELCOME_TEXT: 'Welcome text',
    WELCOME_TITLE: 'Welcome block title',

    NOTIFICATION_LIST: 'Notifications list',
    ERROR_LOAD_NOTIFICATIONS: 'Failed load notifications',
    SEND_EMAIL: 'Send to email',
    PROFILE_LANGUAGE: 'Language',
    PROFILE_PHONE: 'Phone number',
    PROFILE_COUNTRY: 'Country',
    PROFILE_CITY: 'City',
    NOT_CHECKED: 'Not checked',
    HOMEWORK_CHECKED: 'Checked',
    HOMEWORK_REJECTED: 'Declined',
    HOMEWORK_CREATED: 'Created',

    WEBINARS_LIST: "Webinars list",
    WEBINARS_PUBLISHED: "Published",
    WEBINAR_DATE: "event dates",
    WEBINARS_TITLE: "Title",
    WEBINAR_STAGE: "Stage",
    WEBINARS_DATE: "Date",
    WEBINAR_EDIT: "Edit",
    WEBINAR_ADD: "New webinar",
    WEBINARS_NEW_BTN: "Add",
    WEBINARS_SEARCH_PLACEHOLDER: "Search by title",
    WEBINAR_NOT_FOUND: "Webinar not found",
    LOADING_WEBINAR: "Loading",
    WEBINAR_TITLE_FILL: "Enter title",
    WEBINAR_STAGE_FILL: "Select stage",
    WEBINAR_TEXT: "Description + link",

    NOTIFICATION_CREATED_AT: 'Created at',
    NOTIFICATION_TEXT: 'Message',
    NOTIFICATION_LINK: 'Link',
    NOTIFICATION_PROFILES_LIST: 'Profiles',
    NOTIFICATIONS_STAGES: 'Stages',
    NOTIFICATIONS_STAGES_PLACEHOLDER: 'Leave field blanc to send emails for all stages students',
    NOTIFICATIONS_ONLY_APPROVED: 'Send emails only for unapproved students',
    NOTIFICATION_SUBJECT: 'Email subject',
    NOTIFICATION_MESSAGE: 'Email text',
    NOTIFICATION_LOAD_ERROR: 'Error while load notifications list',
    NOTIFICATION_SEND_SUCCESS: 'Emails successfully sent',
    NOTIFICATION_SEND_ERROR: 'Error while sending emails',
    NOTIFICATION_CREATE: 'Create email delivery',
    APPROVED_COUNT: 'Approved:', 
    NOT_APPROVED_COUNT: 'Not approved:',
    NOTIFICATION_SEND: 'Send',
    NOTIFICATION_CLOSE: 'Close',

    PREREQESTS_TITLE: 'Start of new course notifications',
    PREREQUEST_FOR: 'Course notifications',
    PREREQUEST_TEXT: 'Email text',
    PREREQUEST_SUBSCRIBERS_COUNT: 'Subscribers',

    MAILING: "Mailing list",
    PREREQUESTS_LIST: "Begining of new course",
    STAGES_MAILING: "For ongoing courses"
};

export default EN;
