// import MediaSources from "../../media_sources";
import {parseUrlParams} from "../../../core/library/url";

const PROJECT = process.env.PROJECT || "";

export const staging = {
    SHOW_LANGS: true,
    APP_CLASS: 'staging',
    PROJECT_NAME: 'RT-School',
    PROJECT_PREFIX: 'rts',
    LANGS: [
        {title: "Russian", domain: "https://admin-school-vashubin.dev.rt.com"}
    ],
    PROJECT: {
        // project name
        NAME: PROJECT,
        // project default layout
        LAYOUT: "default",
        // project api path
        API: `https://admin-school-vashubin.dev.rt.com/api/v1`,
    },
    OAUTH: {
        // url for oauth domain
        URL: `https://auth.rt.com`,
        // current project domain
        PROJECT_DOMAIN: `admin-school-vashubin.dev.rt.com`,
        // current project url path
        PROJECT_PATH: `https://admin-school-vashubin.dev.rt.com`,
        // path for oauth api
        API: `https://auth.rt.com/auth`,
        // path for oauth users
        OAUTH_USER_PATH: `https://auth.rt.com/users/`,
        // prefix for authorization tokens
        PREFIX: 'oauth_',
        AUTH_HEADER: 'X-Access-Token'
    },
    VIEWS: {},
    MENU: [
        {
            'title': 'DASHBOARD',
            'path': '/',
            'component': 'dashboard',
            'icon': 'dashboard',
        },
        {
            'title': 'Profiles',
            'path': '/profiles',
            'component': 'profiles_controller',
            'icon': 'user',
            'subroutes': [
                {
                    'path': '/profiles/:id',
                    'component': 'profile_edit_controller',
                }
            ]
        },
        {
            'title': 'SUBMITS_LIST',
            'path': '/submits',
            'component': 'submits_controller',
            'icon': 'mail'
        },
        {
            'title': 'COURSES_LIST',
            'path': '/courses',
            'component': 'courses_controller',
            'icon': 'number',
            'subroutes': [
                {
                    'path': '/courses/add',
                    'component': 'courses_add',
                },
                {
                    'path': '/courses/:id',
                    'component': 'courses_add',
                },
                {
                    'path': '/courses/:id/preview',
                    'component': 'course_preview'
                }
            ]
        },
        {
            'title': 'STAGES_LIST',
            'path': '/stages',
            'component': 'stages_controller',
            'icon': 'unordered-list',
            'subroutes': [
                {
                    'path': '/stages/add',
                    'component': 'stage_add',
                },
                {
                    'path': '/stages/:id',
                    'component': 'stage_add',
                },
                {
                    'path': '/stages/:id/preview',
                    'component': 'stage_preview'
                }
            ]
        },
        {
            'title': 'LESSONS_LIST',
            'path': '/lessons',
            'component': 'lessons_controller',
            'icon': 'experiment',
            'subroutes': [
                {
                    'path': '/lessons/add',
                    'component': 'lesson_add',
                },
                {
                    'path': '/lessons/:id',
                    'component': 'lesson_add',
                },
                {
                    'path': '/lessons/:id/preview',
                    'component': 'lesson_preview'
                }
            ]
        },
        {
            'title': 'TEACHERS_LIST',
            'path': "/teachers",
            'component': 'teachers_controller',
            'icon': 'crown',
            'subroutes': [
                {
                    'path': '/teachers/add',
                    'component': 'teacher_add',
                },
                {
                    'path': '/teachers/:id',
                    'component': 'teacher_add'
                },
            ]
        },
        {
            'title': 'HOMEWORKS_LIST',
            'path': '/homeworks',
            'component': 'homeworks_controller',
            'icon': 'file-search',
            'subroutes': [
                {
                    'path': `/homeworks/:id`,
                    'component': 'homework_controller',
                }
            ]
        },
        {
            'title': "MEDIA",
            'path': '/media',
            'component': 'media_controller',
            'icon': 'file-image'
        },
        {
            'title': 'NOTIFICATION_LIST',
            'path': '/notifications',
            'component': 'notification',
            'icon': 'notification',
        },
        {
            'title': "WEBINARS_LIST",
            'path': "/webinars",
            'component': 'webinars_controller',
            'icon': "youtube",
            'subroutes': [
                {
                    'path': '/webinars/add',
                    'component': 'webinar_add'
                },
                {
                    'path': '/webinars/:id',
                    'component': 'webinar_add'
                }
            ]
        },
        {
            'title': 'MAILING',
            'icon': 'bell',
            'path': '/mailing',
            'submenu' : [
                {
                    'title': 'PREREQUESTS_LIST',
                    'path': '/mailing/prerequests',
                    'component': 'preRequests_controller',
                }, 
                {
                    'title': 'STAGES_MAILING',
                    'path': '/mailing/stages',
                    'component': 'mailing_controller'
                }
            ]
        }
    ],
    MEDIA: {
        // path for cdn content
        CDN: {
            IMAGES: "https://admin-school-vashubin.dev.rt.com/files/images",
            VIDEO: "https://admin-school-vashubin.dev.rt.com/files/videos",
            AVATARS: "https://admin-school-vashubin.dev.rt.com/files/avatars/",
            AUDIO: "https://admin-school-vashubin.dev.rt.com/files/audio"
        },
        EMBEDDED_SOURCES: ['youtube.com', 'facebook.com', 'youtu.be'],
        EMEDDED_PREPARE: {
            'youtu.be': (path) => {
                let params = path.split('/');
                return (
                    `<iframe width="560" height="315" src="https://www.youtube.com/embed/${params[params.length - 1]}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
                );
            },
            'youtube.com': (path) => {
                let p = parseUrlParams(path);
                if (!p.v) return ``;
                return (
                    `<iframe width="560" height="315" src="https://www.youtube.com/embed/${p.v}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
                );
            },
            'facebook.com': (url) => {
                return (
                    `<iframe src="https://www.facebook.com/plugins/video.php?href=${encodeURIComponent(url)}&width=500&show_text=false&appId=526970354336335&height=280" width="500" height="280" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media" allowFullScreen="true"></iframe>`
                )
            },
        },
    },
    UPLOAD: {
        "path": "https://admin-school-vashubin.dev.rt.com/api/v1/media"
    },
};
